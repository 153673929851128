<template>
  <div id="nav">
    <transition mode="out-in" name="cards" appear>
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
* {
  font-family: 'Roboto', sans-serif;
}
#nav {
  @apply w-full h-full;
}
.cards-leave-active, .cards-enter-active {
  transition: all 500ms ease;
}
.cards-enter-from, .cards-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

</style>