import jwt from 'jsonwebtoken'

function jwtVerify(token, secret) {
  return jwt.verify(token, secret, (err, decoded) => {
    if (err) return false
    return decoded
  })
}

async function expirationTokenSession(status) {
  if(status === 401) {
    await localStorage.removeItem('av_token')
    router.push({ path: '/login' })
  }
  return
}

export { jwtVerify, expirationTokenSession }