import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      userLogged: '',
      users: '',
      master: [ 3, 4, 8783 ],
      //permissions: []
    }
  },
  mutations: {
    SET_USER_LOGGED(state, payload) {
      return state.userLogged = payload
    },
    SET_USERS(state, payload) {
      return state.users = payload
    },
    // SET_PERMISSIONS(state, payload) {
    //   return state.permissions = payload
    // }
  },
  actions: {
    setUserLogged({ commit }, payload) {
      commit('SET_USER_LOGGED', payload)
    },
    listUsers({ commit }, payload) {
      commit('SET_USERS', payload)
    },
    // listPermissions({ commit }, payload) {
    //   commit('SET_PERMISSIONS', payload)
    // }
  }
})