<template>
  <header>
    <div class="lg:flex justify-center items-center bg-gray-20 text-gray-10 h-10 hidden">
      <nav v-for="item in navGdf" :key="item">
        <a :href="item.url" class="mr-4 capitalize flex items-center text-sm font-light" target="_blank">{{ item?.title }}
          <img
            v-if="item?.image"
            src="../assets/ico-vlibras.svg"
            :alt="item.alt" />
        </a>
      </nav>
    </div>
    <div class="w-full flex items-center lg:justify-start py-4 bg-gradient-to-b from-blue-20 to-blue-10">
      <div class="w-2/12 flex justify-center items-end lg:hidden">
        <div class="icon-container">
          <i @click="modal" class="ri-layout-grid-fill icon"></i>
        </div>
      </div>
      <div class="w-10/12 flex justify-around sm:justify-start items-end gap-2 ml-4">
        <img class="img" src="../assets/logotipo.png" alt="Logo PMDF">
        <div class="text-white">
          <h1 class="hidden sm:block md:text-2xl text-lg leading-tight font-bold uppercase">departamento de controle e correição</h1>
          <h2 class="hidden sm:block md:text-lg text-base leading-tight">Polícia Militar do Distrito Federal</h2>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:h-14 bg-blue-30 text-white p-2">
      <h1 class="sm:hidden leading-tight text-base font-bold uppercase">departamento de controle e correição</h1>
      <h1 class="sm:hidden leading-tight text-sm">Polícia Militar do Distrito Federal</h1>
      <!-- <div>
        <button
          @click="$router.push('/login')"
          class="hover:bg-blue-500 flex justify-center items-center gap-2 p-2 px-4 rounded"
        >
          <i class="ri-global-line"></i>
          <span>Login</span>
        </button>
      </div> -->
    </div>
  </header>
</template>

<script>
import { onUpdated, ref, toRefs } from 'vue';

export default {
  name: 'Header',
  props: { toggleClose: Boolean },
  setup(props, context) {
    const toggle = ref(false);
    const { toggleClose } = toRefs(props);
    const navGdf = [
      {
        title: 'transparência',
        url: 'http://www.transparencia.df.gov.br/#/',
      },
      {
        title: 'ouvidoria',
        url: 'https://ouvidoria.df.gov.br/',
      },
      {
        title: 'acesso à informação',
        url: 'https://www.df.gov.br/category/acesso-a-informacao/',
      },
      {
        title: 'diário oficial',
        url: 'https://www.dodf.df.gov.br/',
      },
      {
        title: 'portal do governo do distrito federal',
        url: 'https://www.df.gov.br/',
      },
      {
        title: 'dados abertos',
        url: 'http://www.dados.df.gov.br/',
      },
      {
        title: '',
        url: 'https://www.gov.br/governodigital/pt-br/vlibras/',
        image: 'ico-vlibras.svg',
        alt:'Libras'
      },
    ];
    onUpdated(() => {
    })
    if (toggle.value !== toggleClose) {
      toggle.value = toggleClose;
    }
    function modal() {
      toggle.value = !toggle.value;
      context.emit('modalev', toggle.value);
    }
    function imageRender(img) {
      return  __dirname + img 
    }
    return {
      toggle,
      navGdf,
      modal,
      imageRender,
    };
  },
};

</script>

<style scoped>
.img {
  @apply w-16 lg:w-20 h-20 lg:h-24;
}
.icon-container {
  @apply flex justify-center items-center h-12 w-12 rounded-full hover:shadow-md hover:bg-blue-400 z-10;
}
.icon {
  @apply text-3xl text-white cursor-pointer;
}

</style>