<template>
  <footer class="flex flex-col justify-center bg-blue-10 text-white">
    <div class="flex flex-col md:flex-row p-4">
      <div class="md:w-1/2">
        <div class="lg:w-1/2 divide-y divide-gray-700">
          <h3 class="text-center capitalize">entre em contato</h3>
          <div class="pt-4">
            <div class="flex items-center text-blue-300">
              <i class="ri-phone-line text-xl mr-4"></i>
              <span>+55 61 3190-9204</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 md:mt-0 md:w-1/2 lg:flex lg:justify-end">
        <div class="lg:w-1/2 divide-y divide-gray-700">
          <h3 class="text-center capitalize">onde estamos</h3>
          <div class="pt-4">
            <iframe
              width="100%"
              height="300"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=pt&amp;q=Lotes%202050-2060,%20SIA%20Trecho%203+(DCC%20-%20PMDF)&amp;t=k&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ><a href="https://www.mapsdirections.info/pt/mapa-circulo-raio/"
                >Medir Círculo no Mapa Google</a
              ></iframe
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bg-blue-30 p-4 text-center text-gray-200">
      <h1>
        Desenvolvido pela STIC - Seção de Tecnologia da Informação e Comunicação
        do DCC.
      </h1>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>