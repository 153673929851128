<template>
  <main class="bg-white grid grid-cols-16 w-full">
    <Header
      class="col-start-1 col-end-17"
      @modalev="modal"
      :toggleClose="modalUpdate"
    ></Header>
    <Sidebar class="desktop hidden lg:block"></Sidebar>
    <section class="content">
      <transition mode="out-in" name="content" appear>
        <router-view />
      </transition>
    </section>
    <section class="lg:hidden">
      <transition-group mode="out-in" appear>
        <MenuModal
          v-if="modalUpdate"
          @close="menuSelected"
          class="absolute top-44"
        ></MenuModal>
      </transition-group>
    </section>
    <Footer class="col-start-1 col-end-17"></Footer>
  </main>
</template>

<script>
import { computed, onUnmounted, ref, watch } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MenuModal from "@/components/MenuModal.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Footer,
    MenuModal,
    Sidebar,
  },
  setup() {
    const toggle = ref(null);
    const modalUpdate = computed(() => toggle.value);

    function modal(e) {
      if (toggle.value === null) toggle.value = true;
      else toggle.value = e;
    }
    function menuSelected(e) {
      watch(() => {
        toggle.value = e;
      });
    }
    onUnmounted(() => {
      toggle.value = null;
    });
    return {
      modal,
      modalUpdate,
      menuSelected,
    };
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 300ms ease;
}
.v-enter-from,
.v-leave-to {
  transform: translate3d(0, -100px, 0);
  opacity: 0;
}
.content-enter-active,
.content-leave-active {
  transition: all 300ms ease;
}
.content-enter-from,
.content-leave-to {
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
}
.desktop {
  @apply col-start-1 col-end-4 lg:col-end-4 xl:col-end-3;
}
.content {
  @apply col-start-1 lg:col-start-4 xl:col-start-3 col-end-17 pb-4 w-full min-h-screen;
}
</style>