<template>
  <aside class="aside-container">
    <router-link 
      to="/"
      class="text-xs mx-2 rounded text-blue-10 font-semibold pl-4 py-1 capitalize flex items-center hover:bg-opacity-50 hover:bg-blue-300 transition duration-300 ease-in-out"
    ><i class="ri-home-2-line icon"></i> Início</router-link>
    <div v-for="(item, index) in menu" :key="index">
      <section
        class="bg-blue-900 text-white capitalize flex justify-center mx-2 my-1 rounded"
      >
        <span class="">{{ item.title }}</span>
        <div class="flex items-center ml-2">
          <span v-if="item.title === 'institucional'" class="text-xs capitalize text-yellow-500">(Em breve!)</span>
        </div>
      </section>
      <section class="leading-tight" v-if="item.submenu">
        <ul v-for="(subitem, index) in item.submenu" :key="subitem+index">
          <li class="flex pl-4 py-1 hover:bg-opacity-50 hover:bg-blue-300 rounded mx-2 transition duration-300 ease-in-out">
            <router-link
              v-if="subitem.path"
              :to="subitem.path"
              class="text-xs capitalize flex items-center w-full"
            >
            <i :class="subitem.icon" class="icon"></i>
            <span class="ml-1 text-blue-10 font-semibold">{{ subitem.title }}</span>
            <span v-if="subitem.new" class="ml-1 font-semibold text-orange-500">(NOVO)</span>
            </router-link>
            <a
              v-else
              :href="subitem.url"
              target="_blank"
              class="cursor-pointer text-xs capitalize flex items-center"
              :class="subitem.dropdown ? 'w-4/5' : 'w-full'"
              @click="toggleNadaConsta(subitem)"
            >
              <i :class="subitem.icon" class="icon"></i>
              <span class="ml-1 text-blue-10 font-semibold">{{ subitem.title }}</span>
            </a>
            <i
              v-if="subitem.dropdown"
              @click="nConstaToggle=!nConstaToggle"
              :class="nConstaToggle ? 'ri-arrow-up-s-line w-1/5':'ri-arrow-down-s-line w-1/5'"
              class="cursor-pointer text-blue-20 flex justify-center"
            ></i>
          </li>
          <transition mode="out-in" appear>
            <div v-if="nConstaToggle && subitem.dropdown">
              <ul class=" flex flex-col items-center text-xs">
                <li class="mx-2 rounded text-blue-10 font-semibold w-full flex justify-center p-2 hover:bg-opacity-50 hover:bg-blue-200 transition duration-300 ease-in-out">
                  <a href="https://sgc.dcc.pm.df.gov.br/nadaconsta/emitirCertidao.faces" target="_blank">Emitir Certidão</a>
                </li>
                <li class="mx-2 rounded text-blue-10 font-semibold w-full flex justify-center p-2 hover:bg-opacity-50 hover:bg-blue-200 transition duration-300 ease-in-out">
                  <a href="https://sgc.dcc.pm.df.gov.br/nadaconsta/validarCertidao.faces" target="_blank">Pesquisar/Validar Certidão</a>
                </li>
              </ul>
            </div>
          </transition>
        </ul>
      </section>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      nConstaToggle: false
    }
  },
  setup() {
    const menu = [
      {
        title: 'serviços',
        submenu: [
          {
            title: 'Certidão Negativa',
            icon: 'ri-file-search-line',
            dropdown: true
          },
          {
            title: 'SGC',
            url: 'https://sgc.dcc.pm.df.gov.br/',
            icon: 'ri-scales-3-line',
          },
          {
            title: 'Recuperar Senha',
            path: 'email',
            icon: 'ri-key-2-line'
          },
          // {
          //   title: 'termo de compromisso',
          //   path: 'termo',
          //   icon: 'ri-draft-line',
          // },
          {
            title: 'suporte técnico - SGC',
            url: 'https://suporte.dcc.pm.df.gov.br/',
            icon: 'ri-customer-service-2-line',
          }
        ],
      },
      // {
      //   title: 'institucional',
      // },
      {
        title: 'links',
        submenu: [
          {
            title: 'SEI',
            url: 'https://sip.df.gov.br/sip/login.php?sigla_orgao_sistema=GDF&sigla_sistema=SEI',
            icon: 'ri-folder-4-line',
          },
          {
            title: 'PMDF',
            url: 'http://www.pmdf.df.gov.br/',
            icon: 'ri-shield-line',
          },
          {
            title: 'Intranet',
            url: 'https://intranet.pmdf.df.gov.br/portal/',
            icon: 'ri-lock-password-line text-green-600 hover:-translate-y-2',
          },
          {
            title: 'SIPOM',
            url: 'https://www.sipom.pm.df.gov.br/intranet',
            icon: 'ri-spy-line text-green-600 hover:-translate-y-2'
          },
          {
            title: 'SGPol',
            url: 'https://sgpol.pm.df.gov.br/login',
            icon: 'ri-team-line',
          },
          {
            title: 'gênesis',
            url: 'https://genesis.pm.df.gov.br/login.aspx?ReturnUrl=%2fdefault.aspx',
            icon: 'ri-global-line',
          },
          {
            title: 'marcação de consulta',
            url: 'http://marcacao.saude.pm.df.gov.br/marcacaoweb/login/login',
            icon: 'ri-stethoscope-line',
          },
          {
            title: 'SVG',
            url: 'http://svg.pm.df.gov.br/SVG/',
            icon: 'ri-user-follow-line',
          },
          {
            title: 'EAD DEC',
            url: 'https://virtual.iscp.edu.br/auth_custom/',
            icon: 'ri-book-mark-line',
          }
        ],
      },
      // {
      //   title: 'contato',
      //   submenu: [
      //     {
      //       title: 'como posso ajudar?',
      //       path: '/ajuda',
      //       icon: 'ri-question-line',
      //     },
      //   ],
      // },
    ];
    
    function toggleNadaConsta(item) {
      if(item.title === 'Certidão Negativa') this.nConstaToggle =! this.nConstaToggle
    }

    return {
      menu,
      toggleNadaConsta
    };
  },
};
</script>

<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: all 300ms ease;
  }
  .v-enter-from,
  .v-leave-to {
    transform: translate3d(0,-10px,0);
    opacity: 0;
  }
  .aside-container {
    @apply bg-white shadow-md rounded text-blue-20 py-2;
  }
  .icon {
    @apply text-blue-20 text-sm flex justify-center items-center p-1 w-6 h-6 rounded-full;
  }
</style>