import http from '@/plugins/axios'
import store from '@/store'
import { createRouter, createWebHashHistory } from 'vue-router';
import Layout from '@/layout/Default.vue';
import Home from '@/views/Home.vue';
import { notificationError } from '@/utils/notification'
import { jwtVerify } from '@/utils/jwt' 

const routes = [
  {
    name: 'Layout',
    path: '/',
    component: Layout,
    children: [
      {
        name: 'Home',
        path: '/',
        component: Home,
        children: [
          {
            name: 'Preventive Internal',
            path: '/',
            component: () => import('@/views/home/Preventive'),
          }
        ]
      },
      {
        name: 'Content',
        path: '/content',
        component: () => import('@/views/Content.vue'),
      },
      {
        name: 'Term',
        path: '/termo',
        component: () => import('@/views/Term.vue'),
      },
      {
        name: 'Remember Password',
        path: '/email',
        component: () => import('@/views/Email.vue'),
      },
      {
        name: 'Forgot Password',
        path: '/forgot-password/:id',
        component: () => import('@/views/ForgotPassword.vue'),
      },
    ],
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    name: 'Acesso Restrito',
    path: '/app.dcc',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/layout/Intranet.vue'),
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        meta: {
          requireAuth: true,
        },
        component: () => import('@/views/intranet/Dashboard.vue'),
      },
      {
        name: 'ListUser',
        path: 'list',
        meta: {
          requireAuth: true,
        },
        component: () => import('@/components/intranet/ListUser.vue'),
      },
      {
        name: 'CreateUser',
        path: 'create',
        meta: {
          requireAuth: true,
        },
        component: () => import('@/components/intranet/ModalCreateUser.vue'),
      },
      {
        name: 'About',
        path: 'about',
        meta: {
          requireAuth: true,
        },
        component: () => import('@/components/intranet/Versions.vue'),
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: { name: 'Home' }
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const user = window.localStorage.getItem('user_session')
  user && await store.dispatch('setUserLogged', JSON.parse(user))

  await http.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('av_token')
      router.push({ path: '/login' })
    }
    return Promise.reject(error)
  })
  const token = localStorage.getItem('av_token')
  const validationToken = await jwtVerify(token, process.env.VUE_APP_KEY)
  if (to.matched.some(record => record.meta.requireAuth)) {
    if(validationToken.iat < validationToken.exp) {
      http.interceptors.request.use(function (config) {
        config.headers.common['Authorization'] = `Bearer ${token}`
        return config
      })
      next()
    } else {
      localStorage.removeItem('av_token')
      localStorage.removeItem('user_session')
      notificationError('Erro!', 'Token expirado! Realizar o login novamente.')
      next({name: 'Login'})
    }
  } else {
    next()
  }
})

export default router;