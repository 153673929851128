<template>
  <main class="relative">
    <div class="w-full md:w-3/5 m-auto py-8 px-8">
      <el-carousel :interval="4000" indicator-position="outside" :autoplay="true" height="400px">
        <!-- <el-carousel-item @click="itemCarrosel('encarregado')">
          <div class="relative flex justify-center items-center">
            <img
              class="img-container object-cover"
              src="../assets/carousel/law.jpg"
              alt=""
            >
            <div class="absolute top-1/2">
              <h3 class="title-img">Legislações de Apoio ao Encarregado</h3>
            </div>
          </div>
        </el-carousel-item> -->
        <el-carousel-item @click="openUrl('https://sgc.dcc.pm.df.gov.br/nadaconsta/emitirCertidao.faces')">
          <div class="relative flex justify-center items-center">
            <img
              class="img-container object-cover"
              src="../assets/carousel/nada-consta.jpg"
              alt=""
            >
            <div class="cursor-pointer absolute top-1/2">
              <h3 class="title-img">Ferramenta de Emissão de Certidão Negativa</h3>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item @click="$router.push('/email')">
          <div class="relative flex justify-center items-center">
            <img
              class="img-container object-contain"
              src="../assets/carousel/login-key.svg"
              alt=""
            >
            <div class="cursor-pointer absolute top-1/2">
              <h3 class="title-img">Link para recuperação de senha do sistema SGC</h3>
            </div>
          </div>
        </el-carousel-item>
        <!-- <el-carousel-item @click="$router.push('/termo')">
          <div class="relative flex justify-center items-center">
            <img
              class="img-container object-contain"
              src="../assets/0001.jpg"
              alt=""
            >
            <div class="cursor-pointer absolute top-1/2">
              <h3 class="title-img">Termo de Compromisso de Manutenção de Sigilo e Responsabilidade (SGC)</h3>
            </div>
          </div>
        </el-carousel-item> -->
        <el-carousel-item @click="openUrl('https://suporte.dcc.pm.df.gov.br/')">
          <div class="relative flex justify-center items-center">
            <img
              class="img-container object-contain"
              src="../assets/carousel/suport-ti.svg"
              alt=""
            >
            <div class="cursor-pointer absolute top-1/2">
              <h3 class="title-img">suporte técnico do sgc</h3>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="flex flex-col">
      <div class="bg-blue-900">
        <h3 ref="encarregado" class="lg:w-3/4 mx-auto text-white text-lg tracking-wide font-semibold  p-2 rounded-sm uppercase">
          Informações Gerais
        </h3>
      </div>
      <div class="bg-blue-10 ">
        <section class="lg:w-3/4 mx-auto flex p-4 gap-8">
          <router-link
            v-for="item in generalInformation"
            :key="item"
            :to="item.path"
            class="cursor-pointer w-32 flex flex-col items-center bg-blue-700 py-12 px-2 rounded-full ring ring-offset-blue-4 ring-offset-blue-600 
          hover:bg-blue-600 hover:shadow-md hover:ring-0">
            <div class="text-center">
              <h3 class="uppercase text-md font-bold text-white">{{ item.title }}</h3>
              <h3 class="uppercase text-yellow-400">{{ item.subtitle }}</h3>
            </div>
          </router-link>
        </section>
      </div>
      <section class="lg:w-3/4 mx-auto rounded-md bg-white">
        <transition mode="out-in" name="cards" appear>
          <keep-alive>
            <router-view v-slot="{ Component }">
              <component :is="Component" />
            </router-view>
          </keep-alive>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
import Historic from './home/Historic.vue'
export default {
  name: 'Dashboard',
  components: {
    Historic
  },
  data() {
    return {
      generalInformation: [
        // {
        //   title: 'Apoio ao',
        //   subtitle: 'Encarregado',
        //   path: '/'
        // },
        {
          title: 'Corregedoria',
          subtitle: '',
          path: '/'
        }
      ],
    }
  },
  methods: {
    itemCarrosel(refName) {
      this.$smoothScroll({
        scrollTo: this.$refs[refName],
        duration: 1000,
        offset: -50,
      })
    },
    openUrl(url) {
      window.open(url, '_blank')
    }
  }
};
</script>

<style scoped>
.cards-leave-active, .cards-enter-active {
  transition: all 500ms ease;
}
.cards-enter-from, .cards-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.img-container {
  @apply border-8 border-blue-700 cursor-pointer shadow-md rounded-md p-2 h-96 w-full bg-white;
}
.title-img {
  @apply uppercase text-lg md:text-xl lg:text-3xl text-center font-bold bg-blue-700 rounded-sm mx-5 text-white p-2;
}
</style>
