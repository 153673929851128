import 'mutationobserver-shim'
import { createApp } from 'vue';
import ApexCharts from 'vue3-apexcharts';
import ElementPlus from 'element-plus';
import SmoothScroll from 'vue3-smooth-scroll'
import Mask from 'vue-the-mask'
import App from './App.vue';
import store from './store';
import router from './router';
import './assets/tailwind.css';
import 'remixicon/fonts/remixicon.css';
import 'element-plus/dist/index.css'

createApp(App)
  .use(store)
  .use(router)
  .use(ApexCharts)
  .use(ElementPlus)
  .use(SmoothScroll)
  .use(Mask)
  .component('apexchart', ApexCharts)
  .mount('#app');