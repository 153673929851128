<template>
  <div>
    <h4 class="title-menu">{{ data.title }}</h4>
    <div class="grid grid-cols-2 justify-items-center">
      <div v-for="item in data.data" :key="item.label">
        <a v-if="item.src"
          class="item"
          :href="item.src"
          target="_blank"
        >
          <i class="icon" :class="item.icon"></i>
          <span class="text-sm text-center">{{ item.label }}</span>
        </a>
        <router-link v-else class="item" :to="item.path">
          <i class="icon" :class="item.icon"></i>
          <span class="text-sm text-center">{{ item.label }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkModal',
  props: ['data'],
};
</script>

<style scoped>
.title-menu {
  @apply col-span-4 bg-blue-900 text-center text-base text-white font-bold rounded;
}
.item {
  @apply w-24 flex flex-col justify-center items-center rounded hover:bg-blue-100 py-2 cursor-pointer;
}
.icon {
  @apply transform duration-500 text-3xl;
}
</style>