import { ElNotification } from 'element-plus'

function notificationSuccess(title, message, duration=5000, html=false, position='top-right') {
 return ElNotification({
    title,
    dangerouslyUseHTMLString: html,
    message,
    type: 'success',
    duration,
    position
  })
}

function notificationError(title, message, duration=5000, html=false, position='top-right') {
  return ElNotification({
    title,
    dangerouslyUseHTMLString: html,
    message,
    type: 'error',
    duration,
    position
  })
} 

function notificationWarning(title, message, duration=5000, html=false, position='top-right') {
  return ElNotification({
    title,
    dangerouslyUseHTMLString: html,
    message,
    type: 'warning',
    duration,
    position
  })
}
export { notificationSuccess, notificationError, notificationWarning }