<template>
  <section>
    <div class="bg-blue-900">
      <h3 class="lg:w-3/4 mx-auto text-white text-lg tracking-wide font-semibold p-2 rounded-sm uppercase">
        História da Corregedoria
      </h3>
    </div>
    <div class="bg-blue-50 p-4">
      <div class="lg:w-3/4 mx-auto">
        <div>
          <img class="sm:w-1/2 rounded-sm shadow-md aspect-square sm:float-right sm:ml-8 mb-4" src="@/assets/sede.png" alt="imagem" />
        </div>  
        <p class="paragraph text-justify">Em 01º de outubro do ano de 1996, foi criado na estrutura organizacional da Polícia Militar do Distrito Federal (PMDF), por meio do Decreto Distrital nº 17.725, um órgão de controle interno da atividade policial militar, então concebido como “Corregedoria”, subordinada diretamente ao Comando-Geral da Corporação.</p>
        <p class="paragraph text-justify">A atividade de controle interno de que se cuida constitui o desenvolvimento de procedimentos destinados a orientar, coordenar, supervisionar e corrigir os desvios de conduta dos integrantes da Corporação, seja no campo disciplinar ou criminal.</p>
        <p class="paragraph text-justify">Diante da evolução da dinâmica das relações da sociedade brasiliense, por meio do crescimento populacional, na criação de novas cidades, aumento da violência e da criminalidade, a Corporação teve a necessidade de desenvolver medidas para enfrentar essa realidade. Com efeito, foram realizadas ações estruturantes tanto do ponto de vista organizacional, quanto do ponto de vista recursos humanos e logístico.</p>
        <p class="paragraph text-justify">Em 06 de novembro de 2009, entrou em vigor a Lei Federal n° 12.086, da qual exsurge medidas de grande impacto organizacional e de capital humano. Em 29 de abril de 2010, foi publicado o Decreto Federal nº 7.165 regulamentando a mencionada Lei. No âmbito do Distrito Federal, o Decreto nº 31.793, de 11 de junho de 2010 (revogado pelo Decreto nº 37.321, de 06 de maio de 2016) foi publicado com a mesma finalidade. É destes atos normativos que se põe em evidência a ampliação das atribuições da Corregedoria, cuja designação passou a ser Departamento de Controle e Correição (DCC), tendo por atribuições: exercer a coordenação geral, a orientação normativa e a execução das atividades inerentes aos sistemas de controle interno, correição, polícia judiciária militar, ouvidoria, ética policial militar e transparência da Corporação; e realizar auditoria e fiscalização nos sistemas contábil, financeiro, orçamentário e patrimonial, atuando prioritariamente de forma preventiva, com foco no desempenho da gestão.</p>
        <p class="paragraph text-justify">Nesse cenário, para exercício das diversas atribuições acima descritas, vem a lume a estrutura orgânica do DCC, idealizada à época. Era composta por dois órgãos de direção setorial, quais sejam a “Auditoria” e a “Ouvidoria”, e a estrutura de controle e correição foi desdobrada em quatro divisões, a saber: Divisão Administrativa (DivAdm); Divisão de Assuntos Internos (DAI); Divisão de Polícia Judiciária Militar (DPJM); Divisão de Correição (DivCor), além da Assessoria Técnico-Jurídica e Seção de Procedimentos Éticos.</p>
        <p class="paragraph text-justify">Após o transcorrer de praticamente uma década o Decreto 38.072, de 20 de março de 2017, veio a alterar a estrutura e a nomenclatura de algumas divisões do Departamento de Controle e Correição. Todavia, com o advento do Decreto n° 10.443, 28 de julho de 2020, o Departamento de Controle e Correição passou a ser composto pela Corregedoria-Adjunta e Auditoria. Dessa evolução histórica e normativa, convém destacar as intensas atividades desenvolvidas no âmbito do DCC, envolvendo a apuração da conduta disciplinar, por meio de Sindicâncias, Memorandos Acusatórios e Procedimentos de Investigação Preliminar. Cita-se ainda o exercício da atividade de polícia judiciária militar, por meio da instauração e instrução de Inquéritos Policiais Militares, bem como do registro de ocorrências no plantão da Seção de Triagem e Registro de Ocorrências (STRO).</p>
        <p class="paragraph text-justify">Nesse cenário, citam-se a criação da audiência de custódia (2015), a ampliação do rol de crimes militares (Lei Federal nº 13.491/2017) e a adoção de procedimentos específicos envolvendo pessoas em situação de vulnerabilidade social (crianças, adolescentes, mulheres e idosos (Instrução Normativa/DCC nº 15, de 05 de abril de 2018. Cita-se, ainda, a condução dos diversos processos administrativos de exclusão, através de Processo Administrativo de Licenciamento, Conselho de Disciplina, Conselho de Justificação, Inquéritos Técnicos e Tomadas de Contas Especial, Custódia de Presos, Carta Precatória e Auto de Prisão em Flagrante.</p>
        <p class="paragraph text-justify">É dentro desse ambiente que o DCC, no cumprimento diário de suas atribuições, se alinha às iniciativas estratégicas da Corporação, no sentido de desenvolver mecanismos de qualidade no atendimento, na transparência, nas relações sociais e humanas, na mediação de conflitos, a fim de assegurar a tutela dos direitos humanos na atividade policial militar, ética profissional e legislação (PMDF, Plano Estratégico 2011/2022 – 2ª ed., p. 39).</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
  .paragraph {
    text-indent: 2rem;
    margin-bottom: 1rem;
  }
</style>